import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import useMedia from '~/hooks/useMedia';
import { fill_horizontal_all_center, fill_horizontal_cross_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton';
import { FromOpenAccount } from '~/modules/investment-consultant/openAccount/formOpenAccount';
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch';
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar';
import { store } from '~/pages/heineken_template/_private/store';
export const AsiajyeStock_Topbar = memo(function AsiajyeStock_Topbar(props) {
    const { isPc, isPad } = useMedia();
    useThemeStore.setState({ theme: 'light' });
    return (<styleds.container>
      {isPc && (<styleds.desktopTopBar>
          <styleds.faviconContent>
            <img src='asiajye_stock/favicon.png' css={css `
                height: 40px;
              `}/>
            <div>大探極系統-股票版</div>
          </styleds.faviconContent>
          <div css={css `
              ${fill_horizontal_all_center};
              justify-content: end;
              gap: 4px;
            `}>
            <FromOpenAccount />
            <ChartingServerSwitch charting={store.charting}/>
            <UserAvatarAsDialogButton />
          </div>
        </styleds.desktopTopBar>)}

      {!isPc && (<Preset_Topbar showLeftBurger={true} componentsInRight={<styleds.mobileTopBar>
              {isPad && <FromOpenAccount />}
              <ChartingServerSwitch charting={store.charting}/>
              <UserAvatarAsDialogButton />
            </styleds.mobileTopBar>}/>)}
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_horizontal_cross_center};
    justify-content: end;
    background: linear-gradient(359deg, #ffffff, #ffffff);
    border-bottom: 1px solid #dbdfe7;
  `,
    desktopTopBar: styled.div `
    ${fill_horizontal_all_center};
    ${jc.spaceBetween};
    padding: 0px 16px;
  `,
    mobileTopBar: styled.div `
    ${fill_horizontal_all_center};
    justify-content: end;
  `,
    faviconContent: styled.div `
    ${fill_horizontal_cross_center};
    font-size: 24px;
    font-weight: 600;
    gap: 16px;
  `,
};
